<template>
  <v-row>
    <v-col class="pt-0 pt-sm-3">
      <v-row
        v-show="loadContent"
        class="fill-height"
        align-content="center"
        justify="center"
        style="height:60vh"
      >
        <v-col
          class="text-subtitle-1 text-center color-navy-soft"
          cols="12"
        >
          Tunggu beberapa saat ...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row v-show="content">
        <v-container
          :style="{
            maxWidth: !$vuetify.breakpoint.smAndUp ? 'unset !important' : '',
          }"
        >
          <v-row>
            <v-col class="pa-0 pa-sm-3" cols="12">
              <v-card>
                <v-card-text>
                  <v-row align="center" justify="space-between">
                    <div class="pa-3">
                      <div class="text-h6">Redeem Voucher Kelas yang Sudah Anda Beli</div>
                      <div class="text- text-body-1 color-navy-soft">
                        Akses kelas anda melalui voucher yang anda dapat setelah membeli
                        kelas
                      </div>
                      <v-alert type="error" text v-show="response !== null">{{ response }}</v-alert>                      
                    </div>
                    <div class="pa-3">
                      <v-form @submit.prevent="submit" lazy-validation ref="form" v-model="valid">
                        <v-row no-gutters>
                          <v-text-field
                            class="mr-3"
                            outlined
                            rounded
                            filled
                            hide-details="auto"
                            placeholder="Masukan kode voucher"
                            v-model="voucher"
                            required
                          >
                          </v-text-field>
                          <v-btn color="primary" elevation="0" rounded :loading="submitting" type="submit">Redeem</v-btn>
                        </v-row>
                      </v-form>
                    </div>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <!-- My class list -->
          <v-row>
            <v-col cols="12">
              <div class="text-h6">Kelas Saya</div>
              <div class="text-body-1 navy-soft--text">
                Berikut daftar kelas yang anda ikuti
              </div>
            </v-col>

            <v-col v-if="!this.listClass.length">
              <v-row justify="center">
                <v-col md="4" sm="6">
                  <v-img
                    class="col-10 mx-auto mt-5 mb-4"
                    src="@/assets/images/data_empty.png"
                  ></v-img>
                  <h6 class="text-h6 text-center mb-5  color-navy-soft">Belum ada kelas yang di ikuti</h6>                  
                </v-col>
              </v-row>
            </v-col>
            <template v-else>              
              <v-col md="4" lg="3" cols="6" v-for="(list, index) in listClass" :key="index">
                <v-card :to="{ name: 'detail-class', params: { id: list.id } }" elevation="0">
                  <v-img
                    height="150"
                    :src="list.program.program_information.cover.url"
                  ></v-img>
                  <div class="pa-4">
                    <div class="font-weight-bold mb-1">
                      {{ list.program.title }}
                    </div>
                    <!-- <div class="navy-soft--text text-body-2 mb-1 text-dots-2">
                    {{ list.program.program_benefits }}
                    </div> -->
                    <!-- <div class="text-caption text-decoration-line-through fs-12">
                      Rp. {{ formatPrice(list.program.program_information.discount_price) }}
                    </div> -->
                    <h3 class="teal--text font-weight-bold mb-2">Rp. {{ formatPrice(list.program.program_information.selling_price) }}</h3>
                    <div class="d-flex align-center mb-2">
                      <v-progress-linear
                        class="mr-2"
                        color="primary"
                        background-color="grey lighten-3"
                        height="6"
                        rounded
                        :value="list.results.progress"
                      ></v-progress-linear>
                      <div class="navy-soft--text">{{ list.results.progress }}%</div>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </template>

          </v-row>
          <!-- end class list -->
        </v-container>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// import RedeemCode from "../../components/RedeemCode.vue";
export default {
  name: "my-class",
  data() {
    return {
      loadContent:false,
      content:false,
      listClass:{},
      voucher:'',
      errors: [],
      valid:true,
      submitting: false,
      response:null,
    };
  },
  components: { 
    // RedeemCode    
  },
  created() {
    this.getClass();    
  },
  methods:{
    getClass() {
      this.loadContent=true;      
      this.axios
        .get(`/users/v1/myprogram/list?sort=created_at&dir=desc`)
        .then((response) => {
          this.content=true;
          this.loadContent=false;
          let res = response.data.data;
          if (response.status == 200) {
            this.submitting = false;
            this.listClass = res.list;
            // console.log(this.lists);
          }
        });
    },
    submit(){
      this.submitting = true;
      const sk=this.$refs.form.validate();
      console.log('form',sk);
      let code = this.voucher.trim();
      if(code != ''){
        setTimeout(() => {

          this.axios
          .post(`/users/v1/myprogram/redeem`, {code},{
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(() => {
            // let res = response.data;
            // console.log(res);
            this.loading = false;
              this.submitting=false;
              this.getClass();
              this.$refs.form.reset();            
          })
          .catch((res) => {
            console.log('data', res.response)
            this.submitting=false;
            this.$refs.form.reset()
            this.response = res.response.data.message;
            setTimeout(() => {
              this.response=null;
            },5000);
          });
        }, 1000);
      }
      else{
        this.submitting=false;
        this.$refs.form.reset();
        this.response=true;
        this.response = "Kode Lisensi tidak boleh kosong";
        setTimeout(() => {
          this.response=null;
        },5000);
      }
    },
    formatPrice(value) {
        let val = (value/1).toFixed(0).replace('.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  }

};
</script>

<style type="text/css">
  .text-dots-2{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
</style>
